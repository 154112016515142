<mat-form-field *ngIf="filterItems.length > 6 && catalogView">
    <mat-label>{{ 'default.companysInstructions' | blah }}</mat-label>
    <input type="text"
        matInput
        [formControl]="selectedCompany"
        [matAutocomplete]="auto"
        (change)="handleEmptyInput($event)">
    <button *ngIf="selectedCompany.value"
        matSuffix
        mat-icon-button
        (click)="emptySearch($event)">
        <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete"
        [displayWith]="displayFn"
        [panelWidth]="'auto'"
        (optionSelected)="selectProduct($event.option.value)">
        <mat-option *ngFor="let option of filteredOptions | async"
            [value]="option">
            {{option.name}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>

<ng-container *ngIf="filterItems.length <= 6 || !catalogView">
    <app-catalog-filter [stacked]="stacked"
        [items]="filterItems"
        (emitItemSelect)="selectProduct($event)">
    </app-catalog-filter>
</ng-container>
