<mat-chip-listbox [class]="stacked ? 'mat-mdc-chip-set-stacked' : ''">
    <mat-chip-option *ngFor="let item of items"
        [selected]="item.selected"
        [selectable]="item.selectable"
        [disabled]="!item.selectable && !item.selected"
        (click)="selectItem(item)"
        [ngClass]="{'chip': true, 'chip-hidden': !item.selectable && !item.selected}">
        {{ item.name }}
    </mat-chip-option>
</mat-chip-listbox>
