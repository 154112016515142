import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthorizationInterceptor } from '@headpower/angular-oauth2-oidc-extensions';
import { BlahModule } from '@headpower/blah-ng';
import { ContentLayoutService, FloatingActionButtonModule, FloatingActionButtonService } from '@headpower/components';
import { HpoLayoutModule } from '@headpower/layout';
import { BlahService } from '@headpower/blah-ng';

import { CatalogFormatterService } from './services/catalog-formatter/catalog-formatter.service';
import { CatalogService } from './services/catalog/catalog.service';
import { CategoryService } from './services/category/category.service';
import { PortalService } from './services/portal/portal.service';
import { InstructionFormatterService } from './services/instruction-formatter/instruction-formatter.service';
import { InstructionService } from './services/instruction/instruction.service';
import { PermissionCategoryService } from './services/permission-category/permission-category.service';
import { BypassServiceWorkerInterceptor } from 'src/app/bypass-service-worker.interceptor';
import { environment } from 'src/environments/environment';
import { TagService } from './services/tag/tag.service';

export function initBlahFactory(blahService: BlahService): Function {
    return () => blahService.init({
        appVersion: environment.version,
        resourceNames: [
            'components',
            'default',
            'error',
            'layout'
        ]
    });
}

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        BlahModule
    ],
    exports: [
        FloatingActionButtonModule,
        HpoLayoutModule,
        BrowserAnimationsModule
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        ContentLayoutService,
        TagService,
        CatalogService,
        CategoryService,
        PortalService,
        InstructionService,
        PermissionCategoryService,
        CatalogFormatterService,
        InstructionFormatterService,
        FloatingActionButtonService,
        { provide: HTTP_INTERCEPTORS, useClass: BypassServiceWorkerInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'fi-FI' },
        {
            // Initialize Blah translation service
            provide: APP_INITIALIZER,
            useFactory: initBlahFactory,
            multi: true,
            deps: [BlahService]
        }
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. You should only import Core modules in the AppModule only.');
        }
    }
}
